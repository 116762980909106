
.banner-text {
  color: black;
  text-align: start;
  margin-bottom: 150px;
}
.banner-heading {
  font-family: Manrope;
  font-weight: 700;
  color: rgb(48, 47, 47);
  font-size: 40px;
  margin-top: 75px;
}
.banner-para {
  font-family: Manrope;
  font-size: 12px;
  font-weight: 500;
  color: rgb(48, 47, 47);
  margin-top: 10px;
}
.banner-link {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 150px;
  height: 40px;
  background-color: #229342;
  text-decoration: none;
  font-family: Manrope;
  font-size: 11px;
  font-weight: 600;
  color: white;
  margin-top: 10px;
}

@media(min-width:992px) {
  .banner-heading {
    font-weight: 800;
    font-size: 50px;
    margin-top: 120px;
    color: #535963;
  }
  .banner-para {
    font-size: 17px;
    font-weight: 500;
    margin-top: 20px;
    color: #535963;
  }
  .banner-link {
    align-items: center;
    width: 215px;
    height: 50px;
    font-size: 12px;
    font-weight: 700;
  }
}
























/* 
*{
  margin: 0;
  padding: 0;
}
.banner-container {
  width: 100%;
  height: 65vh;
}
.banner {
  width: 100%;
  height: 65vh;
  background-image: url(./../../assets/Banner.png);
  background-size: cover;
  background-position: 100%;
}
.banner-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: start;
  display: flex;
}
.banner-heading {
  font-family: Manrope;
  font-weight: 700;
  color: rgb(48, 47, 47);
  font-size: 40px;
  margin-top: 75px;
}
.banner-content p {
  font-family: Manrope;
  font-size: 12px;
  font-weight: 500;
  color: rgb(48, 47, 47);
  margin-top: 10px;
}
.banner-link {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 150px;
  height: 40px;
  background-color: #229342;
  text-decoration: none;
  font-family: Manrope;
  font-size: 11px;
  font-weight: 600;
  color: white;
  margin-top: 10px;
}
@media (min-width: 662px) { 
  .banner-container {
    width: 100%;
    height: 65vh;
  }
  .banner-heading {
    font-weight: 800;
    font-size: 50px;
    margin-top: 120px;
    color: #535963;
  }
  .banner-content p {
    font-size: 15px;
    font-weight: 500;
    margin-top: 20px;
    color: #535963;
  }
}

@media (min-width: 992px) {
  .banner-container {
    width: 100%;
    height: 95vh;
  }
  .banner {
    height: 95vh;
  }
  .banner-heading {
    font-weight: 700;
    font-size: 42px;
    margin-top: 90px;
    color: #535963;
  }
  .banner-content p {
    font-size: 17px;
    font-weight: 500;
    margin-top: 20px;
    color: #535963;
  }
  .banner-link {
    align-items: center;
    width: 215px;
    height: 50px;
    font-size: 12px;
    font-weight: 700;
  }
}

/* servives */
/* .services-container {
  margin-top: -50px;
}
.service-heading {
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 41px;
  text-align: start;
  margin-bottom: 100px;
  visibility: hidden;
  
}
.support-elements {
  background-color: #ffffff;
}
.support-card {
  background-color: #ffffff;
  text-align: start;
  padding: 20px 70px 20px 20px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  position: relative;
}
.support-card::after {
  content: "";
  width: 92%;
  height: 0.6em;
  background-color: #339b51;
  position: absolute;
  bottom: -10px;
  left: 0;
  margin-left: 18px;
}
.support-card span {
  font-family: Manrope;
  font-weight: 700;
  font-size: 22px;
  color: #13223c;
  margin-bottom: 12px;
}

.support-elements {
  display: flex;
  flex-direction: column;
  gap: 34px;
  margin-bottom: 20px;
}

.support-text {
  font-size: 16px;
  padding-top: 14px;
  font-family: Manrope;
  font-weight: 500;
  color: #76787c;
}

@media (min-width: 992px) {
  .support-card {
    background-color: #ffffff;
    text-align: start;
    padding: 30px 90px 50px 40px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  }
  .card-title {
    font-size: 42px;
    margin-top: 71px;
    margin-bottom: 51px;
  }
  .support-elements {
    display: flex;
    flex-direction: row;
  }

  .support-text {
    font-size: 16px;
    padding-top: 12px;
    font-family: Manrope;
    font-weight: 500;
    line-height: 1.7;
  }
}  */
