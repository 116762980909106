.contact-main-container {
  margin-top: 100px;
}
.contact-head {
    text-align: center;
    margin-bottom: 10px;
    /* padding-left: 70px; */
}
.contact-head span{
    font-size: Manrope;
    font-size: 16px;
    font-weight: 500;
    color: #76787C;
    margin-bottom: 8px;
}
.contact-head h1 {
    font-size: Manrope;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 20px;
}

  .contact-img-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 96px;
    padding-bottom: 51px;
   
  }
  .contact-p  {
    font-family: "Poppins";
    font-weight: 500;
    font-style: normal;
    font-size: 18px;  
    padding-top: 22px;
  }

  .contact-form {
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    gap: 20px;  
  }
  .text-field-first {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .contact-form-textarea {
    width: 300px;
  }
  .contact-form-textfield {
    width: 300px;
  }
 
  .contact-form-button {
    border: none;
    background-color: #339B51 !important; 
    padding: 12px 30px !important;
    font-family: Manrope !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    color: white;
    margin-bottom: 39px;
    
  }
  .contact-form span{
    display: block;
    margin: 20px 0;
    color:red;
}
.error-text {
  color: #d00000;
  font-size: 1rem;
  text-align: left;
}
.text-field-error {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

  @media(min-width:992px){
    .contact-div {
      font-size: 26px;
      margin: 57px 10px;
    }
    .contact-head {
        text-align: start;
        margin-bottom: 20px;
        padding-left: 110px;
        
    }
    .contact-head h1 {
        font-size: Manrope;
        font-size: 40px;
        font-weight: 700;
    }

    .contact-img-container {
      margin-top: 100px;
      padding-bottom: 150px;
     
    }
    .contact-p {
      font-size: 26px;
    }
    .text-field-first {
        display: flex;
        flex-direction: row;
        gap: 15px;
      }
      .contact-form-textfield {
        width: 250px;
        
      }
      .contact-form-textarea {
        width: 520px;
      }
      .contact-form-button {
        margin-top: 39px;
        padding: 20px 47px !important;
      }
      
  } 
 