.about-container {
  background-image: url(./../../assets/about-bg.png);
  background-size: cover;
  color: grey;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  width: 100%;
  height: 1050px;
}
.about-content {
  font-family: Manrope;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
}
.about-heading {
  text-align: start;
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 12px;
}
.about-para-div {
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  color: rgba(255, 255, 255, 0.88);
  text-align: center;
  padding: 15px;
}
@media (min-width:662px) {
  .about-container {
    width: 100%;
    height: 950px;
  }
  .about-content {
    line-height: 1.8;
    font-size: 17px;
  }
  .about-heading {
    font-size: 45px;
    margin-bottom: 32px;
  }
  .about-para-div {

  }
}
@media (min-width:992px) {
  .about-container {
    width: 100%;
    height: 715px;
  }
  .about-content {
    line-height: 1.8;
    font-size: 18px;
  }
  .about-heading {
    font-size: 45px;
    margin-bottom: 32px;
  }
  .about-para-div {

  }
}
