.testimonials-container {
  background: #339b51;
  text-align: center;
  padding: 32px 42px 42px 32px;
  /* margin-bottom: 135px; */
}
.testimonials-heading-one {
  font-family: Manrope;
  font-weight: 600;
  color: #13223c;
  font-size: 30px;
  margin-bottom: 16px;
}
.testimonials-heading-two {
  font-family: Manrope;
  font-weight: 700;
  color: #ffffff;
  font-size: 30px;
  margin-bottom: 39px;
}
.left-right-btn {
  margin-top: 19px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
.left-btn,
.right-btn {
  width: 43px;
  height: 43px;
  border: 2px white;
  border-radius: 50%;
  border: 2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}


@media (min-width: 992px) {
  .testimonials-container {
    padding: 72px 0px 92px 72px;
    margin-bottom: 105px;
    text-align: start;
  }
  .testimonials-heading-one {
    font-size: 45px;
    margin-bottom: 16px;
  }
  .testimonials-heading-two {
    font-size: 45px;
    margin-bottom: 49px;
  }
  .left-right-btn {
    margin-top: 19px;
    align-items: start;
    justify-content: start;
  }
  .left-btn,
  .right-btn {
    width: 63px;
    height: 63px;
  }
}

/* testimonials items */
.testimonials-para {
  text-wrap: wrap;
}


.testimonial-item {
  margin-top: 20px;
  background: white;
  text-align: start;
  padding: 20px 20px;
  min-width: 500px;
  min-height: 200px;
  box-shadow: 20px 20px rgb(212, 225, 230);
}

.testimonial-img-name {
  display: flex;
  gap: 5px;
  margin-bottom: 24px;
}
.testimonial-name-place {
  padding: 20px;
}
.testimonial-name-place h5 {
  font-family: Roboto;
  font-weight: 600;
  font-size: 16px;
}
.testimonial-name-place span {
  font-family: Roboto;
  font-weight: 600;
  font-size: 14px;
  color: #76787c;
}
.testimonial-details  {
  /* padding-right: 20px; */
  width: 200px;
  height: 110px;
  overflow: auto;
}


.img-div {
  display: flex;
}
.img-front {
  width: 25px;
  height: 25px;
  background: #f6c215;
  position: absolute;
  z-index: 3;
  margin-top: 22px;
  margin-left: -22px;
  text-align: center;
  padding-top: 2px;
}
.img-front-shadow {
  width: 30px;
  height: 30px;
  background: #e7cd78;
  position: relative;
  margin-left: -44px;
  margin-top: 64px;
  clip-path: polygon(0 0, 100% 100%, 0 100%);
}
.test-img {
  /* background-image: url(./../../assets/rectangle-img.png); 
  background-size: cover;
  background-position: center; */
  width: 91px;
  height: 94px;
  position: relative;
  z-index: 2;
  
}

/* .test-img {
  background-image: url(./../../assets/person1.avif);
  background-size: cover;
  background-position: center;
  width: 71px;
  height: 74px;
  position: relative;
  z-index: 2;
} */
.img-back {
  width: 90px;
  height: 100px;
  background: #13223c;
  position: absolute;
  z-index: 1;
  margin-left: 8px;
  margin-top: 2px;
}
@media (min-width: 662px) {
  .testimonial-details {
    padding: 0px;
    width: 350px;
  }
  .img-back {
    width: 90px;
    height: 100px;
  }

}

@media (min-width: 992px) {
  .testimonial-item {
    width: 700px;
    height: 300px;
    padding: 40px 40px 40px 80px;
  }
  .testimonial-img-name {
    gap: 45px;
    margin-bottom: 24px;
  }
  .testimonial-name-place {
    padding: 20px;
  }
  .testimonial-name-place h5 {
    font-size: 24px;
  }
  .testimonial-name-place span {
    font-size: 14px;
    color: #76787c;
  }
  .testimonial-details {
    padding: 0px;
    width: 450px;
  }

  .img-div {
    display: flex;
  }
  .img-front {
    width: 40px;
    height: 40px;
    position: absolute;
    z-index: 3;
    margin-top: 33px;
    margin-left: -25px;
    text-align: center;
    padding-top: 2px;
  }
  .img-front-shadow {
    width: 30px;
    height: 30px;
    background: #e7cd78;
    position: relative;
    margin-left: -44px;
    margin-top: 64px;
    clip-path: polygon(0 0, 100% 100%, 0 100%);
  }

  .test-img {
    width: 91px;
    height: 94px;
    position: relative;
    z-index: 2;
  }
  .img-back {
    width: 90px;
    height: 100px;
    position: absolute;
    z-index: 1;
    margin-left: 10px;
    margin-top: 2px;
  }
}

