
  .project-img-container img {
    width: 100%;
    height: 250px;
    object-fit: cover !important;
  }
  .center-img {
    width: 100%;
    height: 100%;
    object-fit: cover !important;
    margin-bottom: 29px
    }
  .facilities {
    text-align: start;
    margin-bottom: 33px;
  }
  
  .project-facilities {
    font-family: Manrope;
    font-weight: 700;
    font-size: 16px;
  }
  .building-planinng {
    text-align: start;
    padding: 20px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    margin-bottom: 29px;
}

.building-planinng h5 {
    font-family: Manrope;
    font-weight: 800;
    font-size: 22px;
    margin-bottom: 40px;
}
.building-planinng p {
    font-family: Manrope;
    font-weight: 500;
    font-size: 16px;
    color: #76787C;

}
@media(min-width:992px) {
  
  .project-img-container img {
    width: 100%;
    height: 567px;
    object-fit: cover !important;
  }
  .center-img {
    margin-bottom: 59px
  }
  .building-planinng {
    padding: 40px;
    margin-bottom: 59px;
}
}
  