
.footer-bg {
  background-color: #339B51;
}
.footer-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 20px 10px;
}
.footer-home,
.footer-follow,
.footer-meetus {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
}
.footer-home {
  gap: 13px;
}
.footer-follow,
.footer-meetus {
  gap: 16px;
}
.footer-icon-link {
  display: flex;
  gap: 16px;
  align-items: center;
}
.icons {
  width: 17.5px;
  height: 17.5px;
  color: white;
}
.map {
  width: 100%;
  height: 232.11px;
}
.footer-Nav {
  font-family: Manrope;
  font-weight: 400;
  font-size: 16px;
  color: white;
  text-decoration: none;
}
.follow-text, .follow-home {
  color: #ffffff;
  font-family: Manrope;
  font-size: 26px;
  font-weight: 600;
  position: relative;
  margin-bottom: 7px;
}
.follow-home::after {
  content: "";
  width: 30%;
  height: .1em;
  background-color: #F6C215;
  position: absolute;
  bottom: -2px;
  left: 0;

}
.follow-text::after {
  content: "";
  width: 20%;
  height: .1em;
  background-color: #F6C215;
  position: absolute;
  bottom: -2px;
  left: 0;
}
.icon-text {
  font-family: poppins;
  font-weight: 400;
  font-size: 16px;
  color: white;
  text-decoration: none;
}
.icon-text:hover {
  font-weight: 500;
}

.footer-map {
  align-items: center;
  text-align: center;
  justify-content: center;
}
.footer-span {
  padding: 23px 0px;
  border-top:1px solid white;
  margin: 0;
  color: white;
  font-size: 13px;
  font-weight: 600;
  font-family: poppins;
}
.footer-span-link {
  margin-left: 5px;
  text-decoration: none;
  color: white;
  font-size: 13px;
  font-weight: 600;
  font-family: poppins;
}
@media (min-width: 662px) {
  .footer-follow,
.footer-meetus {
  gap: 21px;
}
  .footer-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 33px 0px;
  }
  .footer-home,
  .footer-follow,
  .footer-meetus {
    justify-content: center;
    align-items: start;
  }
  .map {
    width: 180px;
    height: 132.11px;
  }
}
@media (min-width: 992px) {
  .footer-follow,
.footer-meetus {
  gap: 21px;
}
.footer-container {
  display: flex;
  flex-direction: row;
  padding: 40px 0px;
  gap: 20px;
}
  .footer-home,
  .footer-follow,
  .footer-meetus {
    justify-content: center;
    align-items: start;
    padding: 0px 10px;
  }
  .map {
    width: 280px;
    height: 232.11px;
  }
}