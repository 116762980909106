
/* gallery-modal.css */

.gallery-item {
  margin-bottom: 19px;
  height: 280px;
  }
  
  .gallery-images {
    width: 100%;
    height: auto;
    cursor: pointer;
  }
  
  .modal-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
}
  
  .modal-image {
    width: 80%;
    height: 250px !important;
    object-fit: cover;
  }
  .transparent-modal .modal-content {
    background-color: rgba(255, 255, 255, 0.8);
    
  }
  
  .transparent-modal .modal-backdrop {
    background-color: rgba(0, 0, 0, 0.5); 
  }
  .arrow-btn {
    background-color: transparent;
    border: none;
  }
  .arrow-btn:hover {
    background:transparent; 
  }
  @media(min-width:992px) {
    .modal-image {
      max-width: 80% !important;
      height: 450px !important;
    }
  }
  
  
