.content {
    padding: 20px;
}
.socialmedia-container {
    background-color: #EFF1F5;
}
.social-media-content span {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    color: #76787C;
    position: relative;
}
.social-media-content span::after {
    content: "";
    width: 20%;
    height: 0.1em;
    background-color: #F6C215;
    position: absolute;
    bottom: -8px;
    left: 0;
}
.social-media-content h1 {
    font-family: Manrope;
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 20px;
    margin: 20px 0px 20px 0px;
}
.social-media-content p {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    color: #7A7A7A;

}
.social-media-content {
    text-align: start;
    margin-bottom: 50px;
}
.social-media-footer {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
}
.social-media-footer button {
    font-family: Roboto;
    font-weight: 600;
    font-size: 10px;
    color: white;
    outline: none;
    border: none;
    background: #339B51;
    padding: 0px 20px 0px 20px;
}
.contact-content {
  display: flex;
  flex-direction: column;
  text-align: start;
}
.contact {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.contact-content h6 {
  font-family: Manrope;
  font-weight: 400;
  font-size: 12px;
}
.contact-content h5 {
  font-family: Manrope;
  font-weight: 700;
  font-size: 16px;
}
.phone {
  width: 30px;
  height: 30px;
  margin-top: 10px;
}
.youtube {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }


  

@media(min-width:992px) {
    .content {
        padding: 100px 50px;
    }
    .social-media-content {
        margin-bottom: 50px;
    }
    .social-media-content h1 {
        font-size: 45px;
        font-weight: 700;
        margin-bottom: 30px;
    }
    .social-media-content p {
        padding-right: 100px;
    }
    .social-media-footer button {
        padding: 12px 40px;
        font-size: 12px;
    }
    .social-media-footer {
        justify-content: start;
    }
    .youtube {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0px;
      }
    
    
      
}