.book-banner {
    background-color: #AFA58F;
    margin: 90px 0px 90px 0px;
    padding: 20px 36px;
}
.book-banner-container {
  align-items: center;
  text-align: center;
  justify-content: center;
}
.book-banner-container p {
    font-family: Manrope;
    font-weight: 600;
    font-size: 14px;
    color: #ffff;
    align-items: start;
}
.book-banner-container h1 {
    font-family: Manrope;
    font-weight: 700;
    font-size: 20px;
    color: #ffff;
}
.book-banner-btn {
    background-color: #000000;
    color: #ffffff;
    font-size: 12px;
    outline: none;
    border: none;
    padding: 14px 25px;

  }
  .book {
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  @media (min-width: 662px) {
    .book-banner-container {
      text-align: start;
    }
    .book {
      text-align: end;
    }
    .book-banner-btn {
      margin-top: 12px;
    }
  }
  @media (min-width: 992px) {
    .book {
      text-align: end;
    }
    .book-banner {
      padding: 40px 96px;
  }
  .book-banner-container {
    text-align: start;
    

  }
  .book-banner-container p  {
    font-size: 16px;
  }
    .book-banner-p {

    }
    .book-banner-container h1 {
      font-size: 30px;
    }
    .book-banner-btn {
  
      padding: 19px 35px;
      margin-top: 12px;
    }
  }
