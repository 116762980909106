
.navbar-container {
  background-color: #ffff;
}

.navbar-brand {
   padding:0px; 
   margin:0px;
}
.navbar{
  padding: 0px;
}
.logo-img {
  width: 150px;
}
.brand-logo {
    padding: 10px 0px;
}
.nav-item-center {
  margin-left: 150px;
}

.navbar-contact {
  display: none;
  
}
.navbar-contact-h {
  font-family: Manrope;
  font-size: 12px;
  font-weight: 500;
}
.navbar-contact-number {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 800;
}
.nav-items {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
}
.active {
font-weight: 800px;
font-family: Manrope;
/* font-weight: 600; */
font-size: 16px;
color: black;

}
.contact-nav {
  display: none;

}
@media(min-width:662px) { 
  .navbar-expand-lg .navbar-nav .nav-link { 
    padding:0px 6px;
  }

}
@media(min-width:992px) {
  .logo-img {
    width: 170px;
  }
 
  .navbar-expand-lg .navbar-nav .nav-link { 
    padding:0px 18px 0px 0px;
  }

  .brand-img {
    width: 150px;
  }
  .navbar-contact {
    display: flex;
    
  }
  .navbar-contact {
    display: flex;
  }
  .navbar-contact-h {
    font-size: 12px;
  }
  .navbar-contact-number {
    font-size: 16px;
  }

/* contact */
  
  .contact-content {
    display: flex;
    flex-direction: column;
    text-align: start;
    cursor: pointer;
  }
  .contact-nav {
    display: flex;
    flex-direction: row;
    gap: 5px;
    cursor: pointer !important;
  }
  .contact-content h6 {
    font-family: Manrope;
    font-weight: 400;
    font-size: 12px;
  }
  .contact-content h5 {
    font-family: Manrope;
    font-weight: 700;
    font-size: 16px;
  }
  .phone {
    width: 30px;
    height: 30px;
    margin-top: 10px;
  }
}
