.services {
  margin-top: 0px;
}
.service-heading {
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 41px;
  text-align: start;
  margin-bottom: 100px;
  visibility: hidden;
  
}
.support-elements {
  background-color: #ffffff;
}
.support-card {
  background-color: #ffffff;
  text-align: start;
  padding: 20px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  position: relative;
}
.support-card::after {
  content: "";
  width: 92%;
  height: 0.6em;
  background-color: #339b51;
  position: absolute;
  bottom: -10px;
  left: 0;
  margin-left: 18px;
}
.support-card div {
  font-family: Manrope;
  font-weight: 700;
  font-size: 22px;
  color: #13223c;
  margin-bottom: 12px;
  text-align: center;
}

.support-elements {
  display: flex;
  flex-direction: column;
  gap: 34px;
  margin-bottom: 20px;
}

.support-text {
  font-size: 16px;
  padding-top: 14px;
  font-family: Manrope;
  font-weight: 500;
  color: #76787c;
}

@media (min-width: 992px) {
  .services {
    margin-top: -20px;
  }
  .support-card {
    background-color: #ffffff;
    text-align: start;
    padding: 10px 20px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  }
  .card-title {
    font-size: 42px;
    margin-top: 71px;
    margin-bottom: 51px;
  }
  .support-elements {
    display: flex;
    flex-direction: row;
  }

  .support-text {
    font-size: 16px;
    padding-top: 12px;
    font-family: Manrope;
    font-weight: 500;
    line-height: 1.7;
  }
}
