
/* gallery.css */
 .home-project-title {
  text-align: start;
  margin: 46px 0px;
  font-family: Manrope;
  font-weight: 800;
  font-size: 45px;
}
.gallery-images {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}
.gallery-images:hover {
  transform: scale(1.1) !important;
}
.gallery-image {
    
    margin: 5px;
  } 
/*viewmore */
.main-bnr {
  padding: 0px;
}
.view-more-banner {
  background-color: #afa58f;
  margin-top: 60px;
  margin-bottom: 46px;
  width: 100%;
  padding: 40px 0px;
}
.view-more-banner-p {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
  font-weight: 600;
  font-size: 22px;
  color: white;
}
.view-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.view-more-button {
  color:  #ffff;
  background-color: black;
  font-family: Manrope;
  font-weight: 800;
  font-size: 12px;
  outline: none;
  border: none;
  text-decoration: none;
  padding: 19px 20px;
}
.lightbox-container {
  z-index: 9999;
}

@media (min-width: 772px) {
    .gallery-image {
        width: calc(50% - 10px);
        margin: 5px;
      }
      
}

@media (min-width: 992px) {
    .gallery-image {
        width: calc(25% - 10px);
        margin: 5px;
        display: inline-block;
      }      
      .view-more-banner {
        margin-top: 120px;
        padding: 60px 0px;
        margin-bottom: 86px;
      }
      .view-more-banner-p {
        align-items: start;
        justify-content: start;
        font-weight: 600;
        font-size: 30px;
      }
      .view-button-container {
        align-items: end;
        justify-content: end;
        text-align: end;
      }
      .view-more-button {
        padding: 19px 35px;
      }
     
} 

/* 

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.main {
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
 
}
.gallery {
  display: grid;
  width: 90%;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
}
.gallery__item {
  cursor: pointer;
  overflow: hidden;
}
.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.3s ease-in-out;
}
.gallery-image:hover {
  transform: scale(1.1);
}

@media (max-width: 950px) {
  .gallery {
      grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 550px) {
  .gallery {
      grid-template-columns: repeat(1, 1fr);
  }
} */

/*Image modal*/
/* 
.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.733);
  margin-top: -1px;
  animation: zoom 0.3s ease-in-out;
}

@keyframes zoom {
  from {
      transform: scale(0);
  }
  to {
      transform: scale(1);
  }
} */


/* gallery.css */
/*.home-project-title {
  text-align: start;
  margin: 46px 0px;
  font-family: Manrope;
  font-weight: 800;
  font-size: 45px;
}
.gallery-images {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}
.gallery-images:hover {
  transform: scale(1.1) !important;
}
.gallery-image {
    
    margin: 5px;
  } */


/*viewmore */
/* .main-bnr {
  padding: 0px;
}
.view-more-banner {
  background-color: #afa58f;
  margin-top: 60px;
  margin-bottom: 46px;
  width: 100%;
  padding: 40px 0px;
}
.view-more-banner-p {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
  font-weight: 600;
  font-size: 22px;
  color: white;
}
.view-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.view-more-button {
  color:  #ffff;
  background-color: black;
  font-family: Manrope;
  font-weight: 800;
  font-size: 12px;
  outline: none;
  border: none;
  text-decoration: none;
  padding: 19px 20px;
}

@media (min-width: 992px) {
     
      .view-more-banner {
        margin-top: 120px;
        padding: 60px 0px;
        margin-bottom: 86px;
      }
      .view-more-banner-p {
        align-items: start;
        justify-content: start;
        font-weight: 600;
        font-size: 30px;
      }
      .view-button-container {
        align-items: end;
        justify-content: end;
        text-align: end;
      }
      .view-more-button {
        padding: 19px 35px;
      }
     
} 


 */



















