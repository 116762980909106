.project-container {
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-bottom: 40px;
    /* background-color: #e8e3e3; */
  }
  
  .project-heading {
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 41px;
    text-align: start;
    margin-top: 10px;
  }
  
  .projects-list {
    display: flex;
    flex-wrap: wrap;
    gap: 52px;
    justify-content: center;
  }
  .prj-img-container {
    width: 302px;
    height: 272px;
  }
  .project-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
   
  }
  @media(min-width:992px) {
    .project-container {
      margin-bottom: 80px;
      font-size: 45px;
    }
    .project-heading {
      margin-left: 20px;
      font-size: 45px;
      margin-top: 100px;
    }
    .projects-list {
      display: flex;
      flex-wrap: wrap;
      gap: 28px;
      justify-content: center;
    }
  }


  
   /*project item  */



   .project-card {
    display: flex;
    flex-direction: column;
    text-align: start;
    width: 300px;
    height: 500px;
    /* box-shadow: 4px 3px 10px rgb(0 0 0 / 0.2); */
    margin-bottom: 20px;
  }
  .project-item-background {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0);
  }
  
  .project-name {
    font-family: Manrope;
    font-weight: 700;
    font-size: 24px;
    color: #13223c;
  }
  .support-text {
    font-size: 16px;
    font-family: Manrope;
    font-weight: 500;
    color: #76787c;
    /* padding-top: 0px; */
  }
  .green-box {
    background-color: #339b51;
    height: 10px;
    /* margin-top: 12px; */
  }
  .project-card a {
    font-family: Manrope;
    font-size: 12px;
    font-weight: 600;
    text-decoration: none;
    color: black;
  }
  .project-details {
    padding: 22px;
    background-color: #ffffff;
    margin-bottom: 12px;
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-pack: center !important; 
    text-overflow: ellipsis !important;
    height: 250px;
  }
  .prj-img-container img {
    width: 302px;
    height: 272px;
    object-fit: cover !important;
  }
  .project-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
   
  }
  @media (min-width: 992px) {
    .project-card {
      margin-bottom: 40px;
      cursor: pointer;
      /* width: 280px;
      height: 500px; */
    }
  }
  
  /* view more banner */

  .view-more-project-banner {
    background-color: #afa58f;
    margin-top: 60px;
    padding: 40px 0px;
    margin-bottom: 46px;
  }
  .view-more-project-p {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Roboto;
    font-weight: 600;
    font-size: 22px;
    color: white;
  }
  .view-more-project-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .project-btn {
    color:  #ffff;
    background-color: black;
    font-family: Manrope;
    font-weight: 800;
    font-size: 12px;
    outline: none;
    border: none;
    text-decoration: none;
    padding: 19px 20px;
    
  }
  @media (min-width: 992px) {
    .view-more-project-banner {
      margin-top: 120px;
      padding: 60px 0px;
      margin-bottom: 86px;
    }
    .view-more-project-p {
      align-items: start;
      justify-content: start;
      font-weight: 600;
      font-size: 30px;
    }
    .view-more-project-btn{
      align-items: end;
      justify-content: end;
      text-align: end;
    }
    .project-btn {
      padding: 19px 35px;
    }
  }
  

